<template>
  <div>
    <a
      @click.prevent="open = !open"
      href="#"
      class="flix-btn flix-btn-lg flix-btn-block flix-btn-default"
    >
      <div class="flix-pull-right">
        #{{ entry.booking_id }}
        <flixIcon :id="'expand'" v-if="!open" />
        <flixIcon :id="'collapse-top'" v-if="open" />
      </div>
      <div class="flix-text-left">
        <flixIcon
          :id="'info-sign'"
          style="font-size: 1.5em; margin-right: 5px"
          class="flix-text-info"
        />
        {{ $t('message.show', { name: '' }) }}
      </div>
    </a>

    <transition name="fadeDown">
      <div class="flix-panel-body" v-if="open" :key="open">
        <div class="flix-list-group" style="width: 100%; display: block">
          <entryData :entry="entry" />
          <div class="flix-list-group-item flix-text-right">
            <flixIcon :id="'calendar'" />
            {{ $beautifyDate({ begin: date, end: date }) }}<br />{{
              $t('message.successfullSaved')
            }}
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: { entry: Object },
  components: {
    entryData() {
      return import('@/components/booking/data')
    }
  },
  data() {
    return {
      open: false,
      date: this.$createDate(this.entry.date)
    }
  }
}
</script>
<style scoped lang="sass">

.fadeDown-enter, .fadeDown-leave
  opacity: 0

.fadeDown-enter-active, .fadeDown-leave-active
  animation: fadingDown 0.2s ease-in

.fadeDown-leave-active
  animation-direction: reverse

@keyframes fadingDown
 from
   opacity: 1
   transform: scaleY(0)
   transform-origin: top
 to
   opacity: 1
   transform-origin: top
   transform: scaleY(1)

a.flix-panel-heading
  display: block

.flix-panel-body
  background-color: white
  border-color: black
  padding: 0

@media (min-width: 500px)
  .flix-panel-body
    position: absolute
    left: 0
    width: calc(100% - 30px)
    z-index: 1
    padding: 0
    margin: 0 15px
    margin-top: 1px
    border: 1px solid black

  .flix-list-group
    div
      .flix-list-group-item
        white-space: pre-line
        background-color: white
        font-size: 9pt
        word-break: normal
        word-wrap: break-word
        box-sizing: border-box
        &.flix-active
          background-color: darkgrey
          border-color: darkgrey
          &:hover
            background-color: darkgrey
            border-color: darkgrey
</style>
